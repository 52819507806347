import { AnimatePresence } from 'framer-motion';
import Cookies from 'js-cookie';
import Link from 'next/link';
import React, { FunctionComponent, useState } from 'react';
import { useEffectOnce, useWindowSize } from 'usehooks-ts';

import Button from 'components/Button/Button';
import Text from 'components/Text/Text';
import Toggle from 'components/Toggle/Toggle';
import { useGlobalStore } from 'store';
import { opacityMotionProps } from 'utils/styles/animations';
import { ColorNames, colors } from 'utils/styles/theme';
import { breakpointDesktop } from 'utils/styles/vars';

import { CookieBarVariants } from './CookieBar.animations';

import * as S from './CookieBar.styles';

export type CookieBarTypes = {
  topLabel: string;
  redirectionLink: string;
  redirectionLabel: string;
  manageSettingsText: string;
  buttonText: string;
  necessaryCookieLabel: string;
  functionalityCookieLabel: string;
  analyticsCookieLabel: string;
};

export interface CookieBarProps {
  cookieBarData: CookieBarTypes;
}

const defaultProps: Partial<CookieBarProps> = {
  cookieBarData: {
    topLabel: '',
    redirectionLink: '',
    redirectionLabel: '',
    manageSettingsText: '',
    buttonText: '',
    necessaryCookieLabel: '',
    functionalityCookieLabel: '',
    analyticsCookieLabel: '',
  },
};

const CookieBar: FunctionComponent<CookieBarProps> = ({
  cookieBarData,
  ...rest
}) => {
  const {
    topLabel,
    redirectionLink,
    redirectionLabel,
    manageSettingsText,
    buttonText,
    necessaryCookieLabel,
    functionalityCookieLabel,
    analyticsCookieLabel,
  } = cookieBarData;

  const [isExtended, setIsExtended] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [functionality, setFunctionality] = useState(true);
  const [analytics, setAnalytics] = useState(true);

  const { setCookiesConfirmation } = useGlobalStore();

  const { width } = useWindowSize();

  const isMobile = width < breakpointDesktop;

  const checkCookies = () => {
    const cookies = Cookies.get('dbp.cookiesConsent');
    setIsVisible(!cookies);
  };

  const acceptCookies = () => {
    Cookies.set('dbp.cookiesConsent', 'true', { expires: 365 });
    Cookies.set('dbp.functionality', functionality.toString(), {
      expires: 365,
    });
    Cookies.set('dbp.analytics', analytics.toString(), { expires: 365 });
    setCookiesConfirmation(true);
    setIsVisible(false);
  };

  useEffectOnce(checkCookies);

  const settingsComp = (
    <S.SettingsWrapper
      initial="initial"
      animate={isExtended || !isMobile ? 'visible' : 'initial'}
      exit="exit"
      variants={CookieBarVariants.settings}
    >
      <S.SettingRow opacity={0.3}>
        <Text
          size="small"
          text={necessaryCookieLabel}
          color={ColorNames.lighterMineShaft}
        />
        <Toggle disabled color={colors.lighterMineShaft} initialState />
      </S.SettingRow>

      <S.SettingRow onClick={() => setFunctionality(prev => !prev)}>
        <Text
          size="small"
          text={functionalityCookieLabel}
          color={ColorNames.lighterMineShaft}
        />
        <Toggle
          color={functionality ? colors.black : colors.codGrayLight + 'B5'}
          isActive={functionality}
        />
      </S.SettingRow>

      <S.SettingRow onClick={() => setAnalytics(prev => !prev)}>
        <Text
          size="small"
          text={analyticsCookieLabel}
          color={ColorNames.lighterMineShaft}
        />
        <Toggle
          color={analytics ? colors.black : colors.codGrayLight + 'B5'}
          isActive={analytics}
        />
      </S.SettingRow>
    </S.SettingsWrapper>
  );

  return (
    <AnimatePresence initial>
      {isVisible && (
        <S.CookieBarWrapper {...opacityMotionProps} {...rest}>
          <S.CookieBarGrid>
            <S.TextWrapper>
              <S.InfoWrapper>
                <Text size="small" text={topLabel} />
                &nbsp;
                <Link href={redirectionLink || ''}>
                  <Text as="a" size="small" text={redirectionLabel} />
                </Link>
              </S.InfoWrapper>
            </S.TextWrapper>

            {isExtended && isMobile && settingsComp}
            {!isMobile && settingsComp}

            <S.BottomContentWrapper>
              <S.ButtonWrapper>
                <Button label={buttonText} onClick={acceptCookies} />
              </S.ButtonWrapper>

              {!isExtended && isMobile && (
                <S.UnderlineText
                  size="small"
                  text={manageSettingsText}
                  onClick={() => setIsExtended(prev => !prev)}
                />
              )}
            </S.BottomContentWrapper>
          </S.CookieBarGrid>
        </S.CookieBarWrapper>
      )}
    </AnimatePresence>
  );
};

CookieBar.defaultProps = defaultProps;

export default CookieBar;
