export const ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  WORK: '/work',
  INSIGHTS: '/insights',
  INSIGHT_ARTICLE: '/[insightType]/[slug]',
  PLAY: '/play',
  CASE_STUDY: '/work/[name]',
  JOIN_US: '/join',
  CONTACT: '/contact',
  NOT_FOUND: '/404',
  AWARD: '/awards/[slug]',
  ACCESSIBILITY: '/accessibility',
  PRIVACY_POLICY: '/privacy-policy',
} as const;

export enum Pages {
  index = 'index',
  about = 'about',
  work = 'work',
  insights = 'insights',
  insight = 'insight',
  news = 'news',
  play = 'play',
  caseStudy = 'caseStudy',
  joinus = 'joinUs',
  contact = 'contact',
  join = 'join',
  notFound = 'notFound',
  accessibility = 'accessibility',
  privacyPolicy = 'privacyPolicy',
}
