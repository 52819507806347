import { gql } from 'apollo-boost';

import { getLocales, queryCMS } from 'utils/cms/api';

import { MarketCMS, MarketPageCMS } from './sharedTypes';

export enum MarketPages {
  homePage = 'homePage',
  aboutPage = 'aboutPage',
  workPage = 'workPage',
  contactPage = 'contactPage',
  darkSide = 'darkSide',
  insightsPage = 'insightsPage',
  joinUsPage = 'joinUsPage',
  accessibilityPage = 'accessibilityPage',
  privacyPage = 'privacyPage',
  cookieBar = 'cookieBar',
  footer = 'footer',
}

function marketPageQuery(page: MarketPages) {
  return `
  ${page} {
    id
    locale
    localizations {
      id
      locale
    }
  }
  `;
}

export async function getMarket(page: MarketPages) {
  const marketId = process.env.MARKET;
  return queryCMS({
    query: gql`
        {
          markets(where: { slug: "${marketId}" }) {
            defaultLocale {
              code
            }
            ${marketPageQuery(page)}
          }
        }
      `,
  }).then(response => response.data.markets[0] as MarketCMS);
}

export async function getEffectiveLocale(
  market: MarketCMS,
  browserLocale?: string
) {
  const locales = await getLocales();
  const codes = locales.map(locale => locale.code);
  if (browserLocale) {
    if (codes.indexOf(browserLocale) !== -1) {
      return browserLocale;
    }
  }
  return market.defaultLocale.code;
}

export function getMarketPageId(marketPage: MarketPageCMS, locale: string) {
  for (let i = 0; i < marketPage.localizations.length; ++i) {
    if (marketPage.localizations[i].locale === locale) {
      return marketPage.localizations[i].id;
    }
  }

  return marketPage.id;
}

export const marketPageId = async (page: MarketPages, locale: string) => {
  const market = await getMarket(page);
  const effectiveLocale = await getEffectiveLocale(market, locale);
  const id = getMarketPageId(market[page], effectiveLocale);

  return id;
};
