import React, { FunctionComponent, useEffect, useState } from 'react';

import { colors } from 'utils/styles/theme';

import { ToggleVariants } from './Toggle.animations';

import * as S from './Toggle.styles';

export interface ToggleProps {
  initialState?: boolean;
  color?: string;
  disabled?: boolean;
  isActive?: boolean;
}

const defaultProps: Partial<ToggleProps> = {
  initialState: false,
  color: colors.black,
};

const Toggle: FunctionComponent<ToggleProps> = ({
  initialState,
  color,
  disabled,
  isActive,
  ...rest
}) => {
  const [isToggled, setIsToggled] = useState(initialState);

  useEffect(() => {
    isActive !== undefined && setIsToggled(isActive);
  }, [isActive]);

  return (
    <S.ToggleWrapper
      color={color}
      onClick={() => !disabled && setIsToggled(prev => !prev)}
      {...rest}
    >
      <S.ToggleDot
        initial={initialState ? 'active' : 'inactive'}
        animate={isToggled ? 'active' : 'inActive'}
        variants={ToggleVariants.dot}
        $color={color}
      />
    </S.ToggleWrapper>
  );
};

Toggle.defaultProps = defaultProps;

export default Toggle;
